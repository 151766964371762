<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Edit Data Master Posisi"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Kode" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('kodePosisi')"
            v-model="$v.dataEdit.kodePosisi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Posisi" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('namaPosisi')"
            v-model="$v.dataEdit.namaPosisi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Divisi" label-cols-md="3">
          <multiselect
            :state="checkIfValid('masterDivisiIdEdit')"
            v-model="$v.dataEdit.masterDivisiIdEdit.$model"
            :options="divisi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaDivisi"
            track-by="id"
            @input="itikiwir(dataEdit.masterDivisiIdEdit)"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          Tutup
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "modalEdit",
  components: { Multiselect },
  props: ["data", "divisi", "edit"],
  data() {
    return {
      dataEdit: {
        masterDivisiIdEdit: "",
        namaPosisi: "",
        kodePosisi: "",
      },
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataEdit, null, 4);
    },
    isValid() {
      return !this.$v.dataEdit.$invalid;
    },
    isDirty() {
      return this.$v.dataEdit.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataEdit: {
      kodePosisi: {
        required,
      },
      namaPosisi: {
        required,
      },
      masterDivisiIdEdit: {
        required,
      },
    },
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        // FOREACH diganti FOR
        // this.divisi.forEach((ele) => {
        //   if (ele.id == this.data.masterDivisiId) {
        //     this.data.masterDivisiIdEdit = ele;
        //   }
        // });
        for (let i = 0; i < this.divisi.length; i++) {
          if (this.divisi[i].id == this.data.masterDivisiId) {
            this.dataEdit.masterDivisiIdEdit = this.divisi[i];
          }
          this.dataEdit.kodePosisi = this.data.kodePosisi;
          this.dataEdit.namaPosisi = this.data.namaPosisi;
        }
      }
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataEdit[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      // console.log(x);
    },

    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.data.masterDivisiId = vm.dataEdit.masterDivisiIdEdit.id;
      vm.data.kodePosisi = vm.dataEdit.kodePosisi;
      vm.data.namaPosisi = vm.dataEdit.namaPosisi;
      vm.data.id = vm.data.masterPosisiId;
      axios
        .post(ipBackend + "masterPosisi/update", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH MASTER POSISI",
              showing: true,
            });
            this.$bvModal.hide("modal-edit");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
